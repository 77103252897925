import React from "react"
import PropTypes from "prop-types"

const Instagram = ({margin, color}) => {
  return (
  <a
    href={'https://instagram.com/nitinrgupta'}
    target="_blank"
    rel="noopener noreferrer"
    className={margin}
  >
    <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.0084 8.8397C10.7131 8.8397 8.83999 10.7128 8.83999 13.0081C8.83999 15.3033 10.7131 17.1764 13.0084 17.1764C15.3036 17.1764 17.1767 15.3033 17.1767 13.0081C17.1767 10.7128 15.3036 8.8397 13.0084 8.8397ZM25.5103 13.0081C25.5103 11.2819 25.526 9.57143 25.429 7.84843C25.3321 5.84711 24.8755 4.07095 23.4121 2.60748C21.9455 1.1409 20.1724 0.687473 18.1711 0.590534C16.445 0.493596 14.7345 0.509231 13.0115 0.509231C11.2854 0.509231 9.57485 0.493596 7.85185 0.590534C5.85053 0.687473 4.07436 1.14402 2.6109 2.60748C1.14431 4.07407 0.690891 5.84711 0.593952 7.84843C0.497014 9.57456 0.512649 11.2851 0.512649 13.0081C0.512649 14.7311 0.497014 16.4447 0.593952 18.1677C0.690891 20.169 1.14744 21.9452 2.6109 23.4086C4.07749 24.8752 5.85053 25.3287 7.85185 25.4256C9.57798 25.5225 11.2885 25.5069 13.0115 25.5069C14.7376 25.5069 16.4481 25.5225 18.1711 25.4256C20.1724 25.3287 21.9486 24.8721 23.4121 23.4086C24.8787 21.9421 25.3321 20.169 25.429 18.1677C25.5291 16.4447 25.5103 14.7342 25.5103 13.0081V13.0081ZM13.0084 19.4217C9.45915 19.4217 6.59477 16.5573 6.59477 13.0081C6.59477 9.45886 9.45915 6.59448 13.0084 6.59448C16.5576 6.59448 19.4219 9.45886 19.4219 13.0081C19.4219 16.5573 16.5576 19.4217 13.0084 19.4217ZM19.6846 7.82967C18.856 7.82967 18.1868 7.16048 18.1868 6.33181C18.1868 5.50314 18.856 4.83395 19.6846 4.83395C20.5133 4.83395 21.1825 5.50314 21.1825 6.33181C21.1827 6.52858 21.1442 6.72346 21.069 6.9053C20.9938 7.08714 20.8835 7.25237 20.7443 7.3915C20.6052 7.53064 20.44 7.64096 20.2581 7.71615C20.0763 7.79134 19.8814 7.82991 19.6846 7.82967V7.82967Z" fill={color ? color: "white"}/>
    </svg>
  </a>
  )
}

Instagram.propTypes = {
  margin: PropTypes.string,
  color: PropTypes.string
}

export default Instagram
