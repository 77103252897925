/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { motion, AnimatePresence } from 'framer-motion'

// Components
import Navbar from "./navbar"
import Footer from "./footer"

// Styles
import "../css/components/layout.css"

// const duration = 0.5

// const variants = {
//   initial: {
//     opacity: 0,
//   },
//   enter: {
//     opacity: 1,
//     transition: {
//       duration: duration,
//       delay: duration,
//       when: 'beforeChildren',
//     },
//   },
//   exit: {
//     opacity: 0,
//     transition: { duration: duration },
//   },
// }

const Layout = ({ children }) => {
  return (
    <>
      <Navbar/>
      {/* <AnimatePresence>
        <motion.main
          key={children}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {children}
        </motion.main>
      </AnimatePresence> */}
      <main> {children} </main>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
