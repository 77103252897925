import React from "react"
import PropTypes from "prop-types"

const LinkedIn = ({margin, color}) => {
  return (
  <a
    href={'https://linkedin.com/in/nitinrgupta31'}
    target="_blank"
    rel="noopener noreferrer"
    className={margin}
  >
    <svg width="22" height="20" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.93717 25V8.13254H0.330775V25H5.93717ZM3.1347 5.82819C5.08975 5.82819 6.30667 4.53297 6.30667 2.91437C6.27024 1.25927 5.08982 0 3.1718 0C1.25407 0 0 1.2593 0 2.91437C0 4.53305 1.21662 5.82819 3.0981 5.82819H3.13452H3.1347ZM9.04029 25H14.6467V15.5804C14.6467 15.0763 14.6831 14.5727 14.8312 14.2123C15.2365 13.2051 16.1589 12.1619 17.7077 12.1619C19.7364 12.1619 20.548 13.7087 20.548 15.9762V24.9999H26.154V15.3282C26.154 10.1472 23.3881 7.73654 19.6995 7.73654C16.675 7.73654 15.3471 9.42709 14.6094 10.5785H14.6468V8.13219H9.04042C9.11399 9.71494 9.04042 24.9997 9.04042 24.9997L9.04029 25Z" fill={color ? color: "white"}/>
    </svg>
  </a>
  )
}

LinkedIn.propTypes = {
  margin: PropTypes.string,
  color: PropTypes.string
}

export default LinkedIn
