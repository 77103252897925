import React from "react"
import PropTypes from "prop-types"

const Twitter = ({margin, color}) => {
  return (
  <a
    href={'https://twitter.com/nitinrgupta'}
    target="_blank"
    rel="noopener noreferrer"
    className={ margin}
  >
    <svg width="24" height="20" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.7187 3.69191C27.6729 4.14308 26.5347 4.46779 25.3623 4.59425C26.5795 3.87113 27.491 2.72872 27.9258 1.38136C26.7836 2.06082 25.5324 2.53726 24.2275 2.78956C23.6822 2.20655 23.0226 1.74209 22.2899 1.42514C21.5572 1.10818 20.7671 0.945516 19.9687 0.94728C16.7388 0.94728 14.1411 3.56544 14.1411 6.77833C14.1411 7.22951 14.1958 7.68068 14.2847 8.11476C9.44824 7.86183 5.13477 5.55128 2.26709 2.01369C1.74457 2.90617 1.47075 3.92237 1.47412 4.95656C1.47412 6.97999 2.50293 8.76417 4.07178 9.81349C3.14723 9.77708 2.24434 9.52296 1.43652 9.07179V9.14357C1.43652 11.9771 3.43945 14.3252 6.10889 14.8652C5.60767 14.9954 5.09207 15.062 4.57422 15.0635C4.19482 15.0635 3.83594 15.0259 3.47363 14.9746C4.21191 17.2852 6.36182 18.9634 8.92187 19.0181C6.91895 20.5869 4.41016 21.5098 1.68604 21.5098C1.19727 21.5098 0.746094 21.4927 0.277832 21.438C2.86182 23.0957 5.92773 24.0527 9.22949 24.0527C19.9482 24.0527 25.8135 15.1729 25.8135 7.46535C25.8135 7.21242 25.8135 6.95949 25.7964 6.70656C26.9312 5.87599 27.9258 4.84718 28.7187 3.69191Z" fill={color ? color : "white"}/>
    </svg>
  </a>
  )
}

Twitter.propTypes = {
  margin: PropTypes.string,
  color: PropTypes.string
}

export default Twitter
