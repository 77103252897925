import React from "react"
import PropTypes from "prop-types"

const Dribbble = ({margin, color}) => {
  return (
  <a
    href={'https://dribbble.com/nitinrgupta'}
    target="_blank"
    rel="noopener noreferrer"
    className={margin + ` hover:opacity-75`}
  >
    <svg width="20" height="21" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 12.6221C17.5944 12.6221 16.4144 12.736 15.21 12.9639C14.7705 11.7106 14.2741 10.5387 13.7207 9.44824C15.2344 8.7321 16.5812 7.82878 17.7612 6.73828C18.9412 5.64779 19.873 4.42708 20.5566 3.07617C21.9401 4.24805 23.0265 5.66406 23.8159 7.32422C24.6053 8.98438 25 10.7503 25 12.6221C25 12.9313 24.9837 13.2975 24.9512 13.7207C22.9655 12.9883 20.8984 12.6221 18.75 12.6221ZM12.9639 8.08105C11.2223 5.06999 9.14714 2.889 6.73828 1.53809C8.54492 0.594075 10.4655 0.12207 12.5 0.12207C14.9577 0.12207 17.2201 0.789388 19.2871 2.12402C18.6686 3.39355 17.8182 4.54102 16.7358 5.56641C15.6535 6.5918 14.3962 7.43001 12.9639 8.08105ZM11.5479 8.61816C9.83887 9.2041 8.07292 9.49707 6.25 9.49707C4.36198 9.49707 2.51465 9.17155 0.708008 8.52051C1.57064 6.04655 3.0599 4.03646 5.17578 2.49023C7.64974 3.59701 9.77376 5.63965 11.5479 8.61816ZM6.25 11.0596C8.36589 11.0596 10.3841 10.7096 12.3047 10.0098C12.8255 11.0514 13.2975 12.1582 13.7207 13.3301C11.4095 13.9974 9.39535 15.0716 7.67822 16.5527C5.9611 18.0339 4.71191 19.751 3.93066 21.7041C2.69368 20.5485 1.72933 19.1854 1.0376 17.6147C0.345866 16.0441 0 14.3799 0 12.6221C0 11.7432 0.0895182 10.8724 0.268555 10.0098C2.23796 10.7096 4.23177 11.0596 6.25 11.0596ZM14.1846 14.8193C15.096 17.9118 15.568 21.2077 15.6006 24.707C14.5589 24.9837 13.5254 25.1221 12.5 25.1221C9.83073 25.1221 7.4056 24.3327 5.22461 22.7539C5.8431 20.8984 6.9458 19.2708 8.53271 17.8711C10.1196 16.4714 12.0036 15.4541 14.1846 14.8193ZM15.6982 14.4775C16.7236 14.2822 17.7409 14.1846 18.75 14.1846C20.8008 14.1846 22.7865 14.5589 24.707 15.3076C24.2676 17.3258 23.3765 19.1243 22.0337 20.7031C20.6909 22.2819 19.0674 23.4538 17.1631 24.2188C17.0817 20.752 16.5934 17.5049 15.6982 14.4775Z" fill={color ? color: 'white'} />
    </svg>
  </a>
  )
}

Dribbble.propTypes = {
  margin: PropTypes.string,
  color: PropTypes.string
}

export default Dribbble
