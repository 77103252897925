import { Link } from "gatsby"
import React from "react"
import { motion } from 'framer-motion'

// Styles
import "../css/components/navbar.css"

// Assets
import logo from '../images/logo.png'
import navItems from '../utilities/menu'


// const transition= {
//   type: 'tween',
//   duration: 2
// }

// const navbar = {
//   hidden: { y: 10, opacity: 0 },
//   visible: {
//     opacity: 1,
//     y: 0
//   },
// };


class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.toggleHamburger= this.toggleHamburger.bind(this);

    this.state = {
        hamburgerActive: false,
    };
  }

  toggleHamburger() {
    this.setState({ hamburgerActive: !this.state.hamburgerActive });
  };

  render() {
    return (
      <motion.header className="w-full py-4 sm:py-8 text-center items-center flex flex-col">
        <div className="container px-4 sm:px-4 lg:px-0 inline-flex justify-between items-center">
          <Link
            to={'/'}
          >
            <img src={logo} width="40px" alt="logo" />
          </Link>
          <div className= {
                            "block sm:hidden " +
                            (this.state.hamburgerActive ? "nav-icon-active nav-icon": "nav-icon")
                          } 
                onClick={this.toggleHamburger}>
            <div></div>
          </div>
          <div className="hidden sm:flex flex-row inline-flex">
            {navItems.map(
              item => item.name.toLocaleLowerCase() !== 'resume' ?
                <Link
                  key={item.link}
                  to={item.link}
                  className="ml-10 line"
                >
                  {item.name}
                </Link>
                :
                <a href={item.link} key={item.link} className="ml-10 line" target="_blank" rel="noopener noreferrer">{item.name}</a>
            )}
          </div>
        </div>
        <div className= {
                          "container px-4 flex flex-col mobile-nav w-full text-right " + 
                          (this.state.hamburgerActive ? "mobile-nav-active block" : "hidden")
                        }>
          {navItems.map(
            item => item.name.toLocaleLowerCase() !== 'resume' ?
            <Link
              key={item.link}
              to={item.link}
              className="ml-10 pt-4 line"
            >
              {item.name}
            </Link>
            :
            <a href={item.link} key={item.link} className="ml-10 pt-4 line" target="_blank" rel="noopener noreferrer">{item.name}</a>
          )}
        </div>
      </motion.header>
    )
  }
}

export default Navbar
