import React from "react"
import PropTypes from "prop-types"

const Unsplash = ({margin, color}) => {
  return (
  <a
    href={'https://unsplash.com/nitinrgupta'}
    target="_blank"
    rel="noopener noreferrer"
    className={ margin}
  >
    <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.8125 7.03125V0H17.1875V7.03125H7.8125ZM17.1875 10.9375H25V25H0V10.9375H7.8125V17.9687H17.1875V10.9375Z" fill={color ? color: "white"}/>
    </svg>
  </a>
  )
}

Unsplash.propTypes = {
  margin: PropTypes.string,
  color: PropTypes.string
}

export default Unsplash
